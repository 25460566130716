<template>
  <div class="DialogTitle">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.DialogTitle {
  font-size: 24px;
  font-weight: 500;
}
</style>
